export const DEFAULT_SENTRY_ENABLED = false;
export const DEFAULT_SENTRY_ENVIRONMENT = 'local';
export const DEFAULT_GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-E75C8JWBK2';
export const DEFAULT_AZURE_BASE_URL = 'https://v2.dundle-api.com/api';
export const DEFAULT_CDN_BASE_URL = 'https://cdn.dundle.com';
export const DEFAULT_PAYMENT_BASE_URL = 'https://pay.dundle.com/api';
export const DEFAULT_SEARCH_BASE_URL = 'https://search.dundle.com';
export const DEFAULT_STATIC_BASE_URL = 'https://api.dundle.com';
export const DEFAULT_STATIC_NO_CACHE_URL = 'https://api.dundle.com';
export const DEFAULT_STRAPI_BASE_URL = 'https://strapi.dundle.com';
export const DEFAULT_EVENTS_BASE_URL = 'https://events.dundle.com';
export const DEFAULT_DUNDLE_NEXT_API_ENDPOINT = '/api';

/**
 * The domain we use for testing.
 * Used for monkeypatching some URLs.
 */
export const TEST_DOMAIN = 'dundle.dev';
export const NUXT_STATIC_DOMAIN = 'nuxt.dundle';
