/**
 * DOCUMENTATION LINKS
 * Facebook
 * https://developers.facebook.com/docs/meta-pixel/reference
 *
 * Google
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
 **/
import {
    convertCartItemsToGoogleAnalyticsItemSpec,
    convertProductsToGoogleAnalyticsItemSpec,
    convertProductsToFacebookContentSpec,
    convertCartItemsToFacebookContentSpec,
    convertFloatToMicros,
    convertToFirehoseSpec,
} from './analytics.helpers';
import { SHA256 } from '~/plugins/sha256';

let analytics;
let userProperties = {};

const DEFAULT_CURRENCY = 'EUR';
export default (context, inject) => {
    const analyticsId = '';

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push('consent', 'default', {
        ad_storage: context.app.$config.analytics.google.consentMode,
        ad_user_data: context.app.$config.analytics.google.consentMode,
        ad_personalization: context.app.$config.analytics.google.consentMode,
        analytics_storage: context.app.$config.analytics.google.consentMode,
        wait_for_update: 500,
    });

    function gtag(event) {
        if (event.ecommerce) {
            window.dataLayer?.push({ ecommerce: null });
        }

        window?.dataLayer?.push(event);
    }

    function firehose(eventName, eventData) {
        context.app.$api.firehose.sendEvent({
            event: eventName,
            session: {
                deviceType: context.app.$device.isMobile() ? 'mobile' : 'desktop',
            },
            eventData,
            source: process.env.NODE_ENV === 'production' ? 'dundle.com' : 'dundle.dev',
        });
    }
    const userProps = {
        ...userProperties,
        platform: 'website',
        country: context.app.$locale.currentLocale.regio,
        region: context.app.$locale.currentLocale.regio,
        inDefaultLanguage: !!context.app.$locale.currentLocale.default,
        language: context.app.$locale.currentLocale.language,
        currency: context.app.$locale.currentLocale.currency,
        ab: context.app.$cookies.get('ab') ?? 'nuxt',
    };

    gtag({ event: 'userPropertySet', userProperty: userProps });

    analytics = {
        // Used for google events that aren't defined separately
        event: (eventName, props) => {
            gtag({ event: eventName, ...props });
        },
        getClientId: () => {
            const cookieContent = context.app.$cookies.get('_ga') ?? '';
            return cookieContent.substring(6) || '';
        },
        getUserId: () => {
            return userProperties.user_id ?? '';
        },
        // Return user_properties formatted as { "key": { "value": value } }
        getAnalyticsUserProperties: () => {
            if (!userProperties || Object.keys(userProperties).length === 0) {
                return {};
            }

            const analyticsUserProperties = {};

            Object.keys(userProperties).forEach(key => {
                analyticsUserProperties[key] = { value: userProperties[key] };
            });

            return analyticsUserProperties;
        },
        getSessionId: () => {
            try {
                const cookieContent = context.app.$cookies.get(`_ga_${analyticsId.replace('G-', '')}`) ?? '';
                // remove GS1.1. or GA1.1. see https://www.bbccss.com/explanation-of-cookie-values-used-by-ga4.html
                const sessionId = cookieContent.split('.')[2];
                return sessionId;
            } catch (error) {
                return '';
            }
        },
        // When page is loaded fire this event. This is handled below
        viewPage: (pageTitle, pagePath, fullUrl) => {
            const eventData = {
                page_title: pageTitle,
                page_location: fullUrl,
                page_path: pagePath,
            };
            gtag({ event: 'page_view', ...eventData });
            firehose('page_view', {
                pageTitle,
                fullUrl,
                pagePath,
                utm: context.app.store.getters['session/utm'],
            });
        },
        // Search on the website itself to see what customers are looking for
        search: searchQuery => {
            const eventData = {
                search_term: searchQuery,
            };
            gtag({ event: 'search', ecommerce: eventData });
            firehose('search', {
                searchTerm: searchQuery,
            });
        },
        // View an Product on Dundle
        viewItem: (products, brand = false, currency = null, value = null) => {
            const { gaProducts } = convertProductsToGoogleAnalyticsItemSpec(products, brand);
            const facebookContent = convertProductsToFacebookContentSpec(products, brand, [1], currency);

            const eventData = {
                currency,
                value,
                items: gaProducts,
            };
            gtag({ event: 'view_item', ecommerce: eventData });

            try {
                context.app.$fb.fbq('track', 'ViewContent', facebookContent);
            } catch (error) {}
        },
        // View a list of products with a distinct name like home_page_bestsellers
        viewItemList: (listId, ListName, products, brand = false) => {
            const { gaProducts } = convertProductsToGoogleAnalyticsItemSpec(products, brand);

            // Also set list id and name on products
            gaProducts.forEach(product => {
                product.item_list_id = listId;
                product.item_list_name = ListName;

                return product;
            });

            const eventData = {
                item_list_id: listId,
                item_list_name: ListName,
                items: gaProducts,
            };
            gtag({ event: 'view_item_list', ecommerce: eventData });
        },
        selectPromotion: (creativeName, creativeSlot, promotionId, promotionName) => {
            const eventData = {
                creative_name: creativeName,
                creative_slot: creativeSlot,
                promotion_id: promotionId,
                promotion_name: promotionName,
            };
            gtag({ event: 'select_promotion', ecommerce: eventData });
        },
        // Select item from a list of products like home_page_bestsellers
        selectItemFromItemList: (listId, ListName, products = [], brand = false) => {
            const { gaProducts } = convertProductsToGoogleAnalyticsItemSpec(products, brand);

            // Also set list id and name on selected product
            gaProducts.forEach(product => {
                product.item_list_id = listId;
                product.item_list_name = ListName;
                return product;
            });

            const eventData = {
                item_list_id: listId,
                item_list_name: ListName,
                items: gaProducts,
            };

            gtag({ event: 'select_item', ecommerce: eventData });
        },
        addToCart: (cartItem, currency) => {
            const { gaProducts, totalPrice } = convertCartItemsToGoogleAnalyticsItemSpec([cartItem]);

            const facebookContent = convertCartItemsToFacebookContentSpec([cartItem]);

            try {
                context.app.$fb.fbq('track', 'AddToCart', facebookContent);
            } catch (error) {}

            const eventData = {
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };

            gtag({ event: 'add_to_cart', ecommerce: eventData });

            const { firehoseProducts } = convertToFirehoseSpec([cartItem]);
            firehose('add_to_cart', { ...eventData, items: firehoseProducts });

            postToSift('$add_item_to_cart', context, gaProducts, cartItem, totalPrice);
        },
        removeFromCart: (cartItem, currency) => {
            const { gaProducts, totalPrice } = convertCartItemsToGoogleAnalyticsItemSpec([cartItem]);

            const eventData = {
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };
            gtag({ event: 'remove_from_cart', ecommerce: eventData });

            const { firehoseProducts } = convertToFirehoseSpec([cartItem]);
            firehose('remove_from_cart', { ...eventData, items: firehoseProducts });

            postToSift('$remove_item_from_cart', context, gaProducts, cartItem, totalPrice);
        },
        viewCart: (cartItems = []) => {
            const { gaProducts, totalPrice } = convertCartItemsToGoogleAnalyticsItemSpec(cartItems);
            const eventData = {
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };
            gtag({ event: 'view_cart', ecommerce: eventData });
        },
        addShippingInfo: (cartItems = []) => {
            const { gaProducts, totalPrice } = convertCartItemsToGoogleAnalyticsItemSpec(cartItems);
            const eventData = {
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };
            gtag({ event: 'add_shipping_info', ecommerce: eventData });
        },
        addPaymentInfo: (paymentMethod, products = []) => {
            const { gaProducts, totalPrice } = convertProductsToGoogleAnalyticsItemSpec(products);
            const facebookContent = convertProductsToFacebookContentSpec(products);

            const eventData = {
                payment_type: paymentMethod,
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };

            gtag({ event: 'add_payment_info', ecommerce: eventData });

            const { firehoseProducts } = convertToFirehoseSpec(products);
            firehose('add_payment_info', {
                paymentType: eventData.payment_type,
                currency: DEFAULT_CURRENCY,
                value: eventData.value,
                items: firehoseProducts,
            });

            try {
                context.app.$fb.fbq('track', 'AddPaymentInfo', facebookContent);
            } catch (error) {}
        },
        addDiscountCode({ currency, value }) {
            // The endpoint for adding discount only returns with the value of the discount, if value is 'null' it failed
            context.app.$api.sift.postToSift({
                $type: '$add_promotion',
                $session_id: context.store.$session.id,
                $user_id: context.store.$auth.$state.user?.email || context.store.$auth.$state.user?.userId || null,
                $user_email: context.store.$auth.$state.user?.email || null,
                $promotions: [
                    {
                        $promotion_id: 'AddDiscountCode',
                        $status: value ? '$success' : '$failure',
                        $failure_reason: value ? null : '$invalid_code',
                        $discount: {
                            $amount: value
                                ? convertFloatToMicros(value, context.app.$currency.exchangeTo(currency))
                                : null,
                            $currency_code: value ? currency : null,
                        },
                    },
                ],
            });
        },
        // Start from checkout process
        startCheckout: cartItems => {
            const { gaProducts, totalPrice } = convertCartItemsToGoogleAnalyticsItemSpec(cartItems);
            const facebookContent = convertCartItemsToFacebookContentSpec(cartItems);

            const eventData = {
                currency: DEFAULT_CURRENCY,
                value: totalPrice,
                items: gaProducts,
            };
            gtag({ event: 'begin_checkout', ecommerce: eventData });

            const firehoseItems = convertToFirehoseSpec(cartItems);
            firehose('begin_checkout', { ...eventData, items: firehoseItems });

            try {
                context.app.$fb.fbq('track', 'InitiateCheckout', facebookContent);
            } catch (error) {}
        },
        logPurchase: order => {
            try {
                const { gaProducts } = convertProductsToGoogleAnalyticsItemSpec(order.products);

                gtag({
                    event: 'purchase',
                    ecommerce: {
                        margin: order.margin,
                        value: order.transaction.amount,
                        currency: order.transaction.currency,
                        transaction_id: order.orderId,
                        payment_method: order.transaction.method,
                        items: gaProducts,
                    },
                });
            } catch (error) {
                context.app.$sentry.captureException(error);
            }
        },
    };

    inject('analytics', analytics);

    // Normalizes the titles for urls so it is easier to work with when making reports
    // This also handles the user properties that need to be set every page load for
    // it work. Important to also create the custom definition inside the google analytics
    // dashboard when adding new user properties otherwise they don't show up.
    context.app.router.afterEach(async (to, from) => {
        userProperties = {
            platform: 'website',
            country: context.app.$locale.currentLocale.regio,
            region: context.app.$locale.currentLocale.regio,
            inDefaultLanguage: !!context.app.$locale.currentLocale.default,
            language: context.app.$locale.currentLocale.language,
            currency: context.app.$locale.currentLocale.currency,
            ab: context.app.$cookies.get('ab') ?? 'nuxt',
        };

        // when a user has filled in their email on the delivery page
        // we use that email to identify the user.
        if (context.app.$auth.user) {
            userProperties.user_id = await SHA256(context.app.$auth.user.email);
        }

        gtag({ event: 'userPropertySet', userProperty: userProperties });

        const { name, params } = getPageNameAndParams(context, to);

        const pageViewPath = to.fullPath;
        let pageViewTitle = name;
        switch (name) {
            case 'about':
                pageViewTitle = `About `;
                break;
            case 'brand':
                pageViewTitle = `Brand: ${params.brand}`;
                break;
            case 'brand-faq':
                pageViewTitle = `Brand: ${params.brand} - FAQ`;
                break;
            case 'brand-faq-howto':
                pageViewTitle = `Brand: ${params.brand} FAQ ${params.howto}`;
                break;
            case 'brand-product':
                pageViewTitle = `Product: ${params.brand}`;
                break;
            case 'cart':
                analytics.viewCart(context.app.$cart.items);
                pageViewTitle = `Cart`;
                break;
            case 'category-slug':
                pageViewTitle = `Category: ${params.slug}`;
                break;
            case 'checkout':
                pageViewTitle = `Choose Payment Method`;
                break;
            case 'delivery':
                pageViewTitle = 'Enter Email for delivery';
                break;
            case 'game-slug':
                pageViewTitle = `Game: ${params.slug}`;
                break;
            case 'index':
                pageViewTitle = `Homepage`;
                break;
            case 'legal':
                pageViewTitle = `Category: ${params.slug}`;
                break;
            case 'legal-impressum':
                pageViewTitle = `Impressum - Legal`;
                break;
            case 'legal-terms-and-conditions':
                pageViewTitle = `General Terms and Conditions`;
                break;
            case 'legal-privacy':
                pageViewTitle = `Privacy Policy`;
                break;
            case 'legal-return-policy':
                pageViewTitle = `Return Policy`;
                break;
            case 'legal-complaint-policy':
                pageViewTitle = `Complaint Policy`;
                break;
            case 'order-id':
                pageViewTitle = `Order ID`;
                break;
            case 'order-history-id':
                pageViewTitle = `Order History`;
                break;
            case 'return':
                pageViewTitle = 'Order delivery';
                break;
            case 'reward-info':
                pageViewTitle = 'Dundle World info';
                break;
            case 'rewards':
                pageViewTitle = 'My Dundle World rewards';
                break;
            case 'search':
                pageViewTitle = 'Search';
                break;
            case 'support':
                pageViewTitle = 'Support';
                break;
            case 'support-category':
                pageViewTitle = `Support Category: ${params.category}`;
                break;
            case 'support-category-subcategory':
                pageViewTitle = `Support Category: ${params.category} - ${params.subcategory}`;
                break;
            case 'support-category-subcategory-page':
                pageViewTitle = `Support Category: ${params.category} - ${params.subcategory} - ${params.page}`;
                break;
            case 'support-answer-phishing-and-fraud-prevention':
                pageViewTitle = `Support Phishing and Fraud Prevention`;
                break;
            default:
                // makes search -> Search
                pageViewTitle = `UNTAGGED ${name.charAt(0).toUpperCase() + name.slice(1)}`;

                if (name.includes('service')) {
                    pageViewTitle = `Service ${name}`;
                } else if (name.includes('warning')) {
                    pageViewTitle = `Warning ${name}`;
                }
                break;
        }

        analytics.viewPage(pageViewTitle, pageViewPath, `https://dundle.com${to.fullPath}`);
    });
};

function getPageNameAndParams(context, to) {
    const { name, params } = context.app?.$locale?.reverseRoute(to);

    const nameComponents = to?.name.split(';') || [];
    return {
        name: name ?? nameComponents[nameComponents.length - 1] ?? to?.name ?? 'unknown',
        params: params ?? to?.params,
    };
}

function postToSift(eventname, context, gaProducts, cartItem, totalPrice) {
    const currency = context.app.$locale.currency;
    try {
        context.app.$api.sift
            .postToSift({
                $type: eventname,
                $session_id: context.store.$session.id,
                $user_id: context.store.$auth.$state.user?.email || context.store.$auth.$state.user?.userId || null,
                $user_email: context.store.$auth.$state.user?.email || null,
                $item: {
                    $item_id: gaProducts[0].item_id,
                    $product_title: context.app.$product.getName(cartItem.product),
                    $price: convertFloatToMicros(totalPrice, context.app.$currency.exchangeTo(currency)),
                    $currency_code: currency,
                    $brand: gaProducts[0].item_brand,
                    $category: gaProducts[0].item_category,
                    $quantity: gaProducts[0].quantity,
                },
            })
            .catch(error => {
                context.app.$sentry.captureException(error);
            });
    } catch (error) {
        context.app.$sentry.captureException(error);
    }
}
